<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show"
    max-width="700px"
    scrollable
    persistent
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-else>
      <v-card-title
        class="pb-0"
        style="background-color: #7253cf; color: white"
      >
        <img src="@/assets/teamModalLogo.svg" height="100" width="100" />

        <div class="ml-7 dialogTitle-text">Edit Public Page</div>
        <v-spacer></v-spacer>
        <v-icon
          text
          large
          color="white"
          @click="toggleAddEditViewTeacherModal({ show: false })"
          style="cursor: pointer; margin-top: -80px; margin-right: -15px"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form>
          <v-row style="border-bottom: 1px solid #929292" class="px-4">
            <v-col cols="6" sm="3" style="border-right: 1px solid #929292">
              <div class="profilePicture">
                <div class="profilePictureImage d-flex justify-center">
                  <label for="inputId" ref="imgRef" style="cursor: pointer">
                    <div v-if="!previewImage">
                      <img
                        src="@/assets/publicDonationPage/createStudent/defaultProfile.svg"
                        width="95"
                        alt=""
                      />
                      <img
                        src="@/assets/publicDonationPage/createStudent/plus.svg"
                        width="20"
                        alt=""
                        style="position: relative; right: 28px"
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="previewImage"
                        alt=""
                        width="110"
                        style="max-height: 100px; min-height: 100px"
                      />
                    </div>
                  </label>
                </div>
                <div
                  class="profilePictureInstruction text-center"
                  @click="$refs.imgRef.click()"
                >
                  <span v-if="!previewImage">Upload Profile Photo</span>
                  <span v-else>Change Profile Photo</span>
                </div>
              </div>
              <!-- <v-file-input
                id="inputId"
                style="display: none"
                v-model="imgFile"
                :disabled="formLoading"
                @change="previewProfilePhoto"
                accept="image/*,video/*"
                capture="user,environment"
              > 
              </v-file-input> -->
              <input
                id="inputId"
                ref="fileInput"
                type="file"
                style="display: none"
                accept="image/*"
                :disabled="formLoading"
                @change="previewProfilePhoto"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <label
                for="inputIds"
                class="labelGallery d-flex justify-center text-center"
              >
                <div class="assetsGallery" v-if="!fileLoader">
                  <div>
                    <img
                      src="@/assets/publicDonationPage/createStudent/galleryPlus.svg"
                      width="25"
                      alt=""
                    />
                  </div>
                  <div style="color: #2c1963">
                    Upload Photos & Videos for Gallery
                  </div>
                </div>
                <div class="assetsGallery" v-else>
                  <h3 style="color: #2c1963; font-size: 13px">
                    Uploading {{ fileCount }}/{{ fileLenght }}
                  </h3>
                  <p style="color: #404040; font-size: 10px">
                    {{ progressPercentage }}%
                  </p>
                  <div class="px-6" style="margin-top: -15px">
                    <v-progress-linear
                      color="#7253CF"
                      indeterminate
                      class="py-0"
                      height="25"
                    ></v-progress-linear>
                  </div>
                </div>
              </label>
              <!--<v-file-input
                id="inputIds"
                style="display: none"
                v-model="imgAssets"
                :disabled="fileLoader"
                @change="uploadAssets"
                accept="image/*,video/*"
                capture="user,environment"
                multiple
              >
              </v-file-input>-->
              <input
                type="file"
                multiple
                style="display: none"
                id="inputIds"
                ref="inputGallery"
                accept="image/*,video/*"
                :disabled="fileLoader"
                @change="uploadAssets"
              />
            </v-col>
            <v-col cols="12" sm="6" v-if="assestStoreList[0]">
              <v-sheet
                class="mx-auto"
                max-width="100%"
                min-height="100%"
                color="#f7f7f7"
              >
                <v-slide-group
                  multiple
                  :show-arrows="$vuetify.breakpoint.smAndUp"
                >
                  <template v-slot:prev>
                    <v-icon color="#D30024">mdi-chevron-left</v-icon>
                  </template>
                  <template v-slot:next>
                    <v-icon color="#D30024">mdi-chevron-right</v-icon>
                  </template>
                  <v-slide-item
                    v-for="(item, i) in assestStoreList"
                    :key="i"
                    class="mx-3"
                  >
                    <div class="imageVideoDiv" v-if="item.type === 'image'">
                      <div style="height: 100%">
                        <img
                          :src="item.file_url"
                          alt=""
                          style="
                            max-width: 110px;
                            max-height: 110px;
                            border-radius: 5px;
                          "
                        />
                      </div>
                      <div class="deleteBTNDiv" @click="deleteAssets(item.id)">
                        <v-icon size="18" color="red">mdi-delete</v-icon>
                      </div>
                    </div>
                    <div class="imageVideoDiv" v-else>
                      <video
                        :src="item.file_url"
                        style="
                          width: 110px;
                          height: 110px;
                          object-fit: cover;
                          border-radius: 5px;
                        "
                      ></video>
                      <img
                        src="@/assets/play.png"
                        width="30"
                        style="position: relative; bottom: 35%; right: 60%"
                      />
                      <div class="deleteBTNDiv" @click="deleteAssets(item.id)">
                        <v-icon size="18" color="red">mdi-delete</v-icon>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row class="px-4 pt-7" no-gutters>
            <v-col class="pr-4">
              <v-text-field
                label="Teacher Name"
                outlined
                dense
                v-model="teacherName"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col class="pl-4">
              <v-text-field
                label="Display Name"
                outlined
                dense
                v-model="displayName"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="px-4">
              <v-text-field
                label="Email"
                outlined
                dense
                v-model="teacherEmail"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4">
            <v-col
              :class="
                $route.matched[0].path.substring(1) === 'teacher' ? 'pr-4' : ''
              "
            >
              <v-text-field
                label="Goal"
                outlined
                dense
                v-model="teacherGoal"
                class="formFields"
                color="#7253CF"
                type="number"
                :disabled="formLoading"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#067605">mdi-currency-usd</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              class="pl-4"
              v-if="$route.matched[0].path.substring(1) === 'teacher'"
            >
              <v-autocomplete
                label="Grade"
                v-model="grade"
                :items="gradeList"
                outlined
                dense
                item-text="grade_title"
                item-value="id"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="px-4" no-gutters>
            <v-col cols="12">
              <v-textarea
                outlined
                row-height="4"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                v-model="specialMessage"
                label="Special Message"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          class="rounded-lg dialogAction-btnText px-15"
          height="43px"
          dark
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
        >
          <span>Save</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_TEACHER_ASSETS,
  API_MASTER_GET_GRADE,
  API_ADMIN_TEACHER_MANAGEMENT_GET,
  API_ADMIN_TEACHER_MANAGEMENT_UPDATE,
} from "@/constants/APIUrls";
export default {
  name: "TeacherUpdateModal",
  data() {
    return {
      loading: false,
      formLoading: false,
      imgFile: {},
      previewImage: null,
      imgAssets: [],
      assestStoreList: [],
      fileLoader: false,
      fileCount: 0,
      fileLenght: 0,
      teacherName: "",
      displayName: "",
      teacherEmail: "",
      teacherGoal: null,
      grade: null,
      gradeList: [],
      specialMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "teacherManagement/getTeacherShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    getCampaignDetail() {
      return this.selectedCampaign;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewTeacherModal({ show: value });
      },
    },
    progressPercentage() {
      return Math.trunc((this.fileCount / this.fileLenght) * 100);
    },
  },
  watch: {
    show(value) {
      console.log(value, "watching");
      if (value) {
        this.openCallModal();
      } else {
        this.closeCallModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewTeacherModal: "teacherManagement/toggleTeacher",
      showToast: "snackBar/showToast",
    }),
    openCallModal() {
      this.getGrade();
    },
    closeCallModal() {
      this.loading = false;
      this.formLoading = false;
      this.imgFile = {};
      this.previewImage = null;
      this.imgAssets = [];
      this.assestStoreList = [];
      this.fileLoader = false;
      this.fileCount = 0;
      this.fileLenght = 0;
      this.teacherName = "";
      this.displayName = "";
      this.teacherEmail = "";
      this.teacherGoal = null;
      this.grade = null;
      this.gradeList = [];
      this.specialMessage = "";
    },
    previewProfilePhoto(payload) {
      console.log(payload, "payload");
      // let file = payload;
      // this.imgFile = payload;
      // if (file) {
      //   this.previewImage = URL.createObjectURL(file);
      //   URL.revokeObjectURL(file);
      // } else {
      //   this.previewImage = null;
      // }

      let input = this.$refs.fileInput;
      let file = input.files;
      this.imgFile = file[0];
      console.log(this.imgFile);
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      } else {
        this.previewImage = null;
      }
    },
    uploadAssets() {
      let input = this.$refs.inputGallery;
      let file = input.files;
      console.log(file, "files");
      this.imgAssets = file;

      this.fileCount = 0;
      this.fileLenght = Object.keys(this.imgAssets).length;
      if (this.fileLenght) this.fileLoader = true;
      for (const obj of this.imgAssets) {
        const successHandler = (res) => {
          console.log(res.data);
          let img = res.data.campaign_assests_detail;
          this.assestStoreList.push({
            file: img.file,
            file_url: img.file_url,
            id: img.id,
            type: img.type,
          });
          this.fileCount = this.fileCount + 1;
          if (this.fileCount === this.fileLenght) this.fileLoader = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.fileLoader = false;
        };
        let formData = new FormData();
        console.log(obj);
        formData.append("file", obj);
        formData.append("type", obj.type.split("/")[0]);
        formData.append("campaign", this.getCampaignDetail.campaignData.id);
        Axios.request_POST(
          API_TEACHER_ASSETS,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    deleteAssets(ID) {
      console.log(ID);
      const successHandler = (res) => {
        console.log(res);
        this.assestStoreList.splice(
          this.assestStoreList.findIndex((item) => item.id == ID),
          1
        );
        this.showToast({
          message: "Deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something went wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["assests_id"] = ID;
      Axios.request_DELETE(
        API_TEACHER_ASSETS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getGrade() {
      const self = this;

      self.loading = true;

      const successHandler = (res) => {
        const data = res.data;
        console.log(data, "grades");
        self.gradeList = data.grade_list;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = () => {
        this.getTeacherDetails();
      };
      const formJson = {};
      Axios.request_GET(
        API_MASTER_GET_GRADE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getTeacherDetails() {
      const self = this;

      const successHandler = (res) => {
        let data = res.data;
        console.log(data);
        self.teacherName = data.teacher_detail.teacher_name;
        self.displayName = data.teacher_detail.display_name;
        self.teacherEmail = data.teacher_detail.teacher_email;
        self.teacherGoal = data.teacher_detail.teacher_goal;
        self.grade = data.teacher_detail.grade;
        self.specialMessage = data.teacher_detail.teacher_special_message;
        self.previewImage = data.teacher_detail.profile_pic;
        self.assestStoreList = data.teacher_assests_list;
        self.loading = false;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data, "failed");
        self.loading = false;
      };
      let formData = {};
      formData["teacher_id"] =
        this.$store.state.teacherManagement.teacherUpdateModal.teacherID;
      console.log(formData);
      Axios.request_GET(
        API_ADMIN_TEACHER_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.toggleAddEditViewTeacherModal({ show: false });
        this.showToast({
          message: "Teacher Detail Updated",
          color: "s",
        });
        this.$emit("reload");
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: "Something went wrong",
          color: "e",
        });
      };
      let formData = new FormData();
      if (this.imgFile instanceof File) {
        formData.append("profile_pic", this.imgFile);
      }
      if (this.assestStoreList[0]) {
        formData.append("assests_list", JSON.stringify(this.assestStoreList));
      }
      formData.append("teacher_name", this.teacherName);
      formData.append("display_name", this.displayName);
      formData.append("teacher_email", this.teacherEmail);
      formData.append("teacher_goal", this.teacherGoal);
      if (this.grade) {
        formData.append("grade", this.grade);
      }
      formData.append("teacher_special_message", this.specialMessage);
      formData.append(
        "teacher_id",
        this.$store.state.teacherManagement.teacherUpdateModal.teacherID
      );
      Axios.request_PATCH(
        API_ADMIN_TEACHER_MANAGEMENT_UPDATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.profilePictureInstruction {
  color: #2c1963;
  letter-spacing: 0px;
  cursor: pointer;
}
.profilePictureInstruction >>> span {
  border-bottom: 1px solid #2c1963;
}
.profilePictureImage {
  cursor: pointer;
}
.assetsGallery {
  width: 100%;
  margin-top: 25%;
  justify-items: center;
}
.labelGallery {
  border: 1px dashed #2c1963;
  width: 100%;
  height: 100%;
  background: rgba(44, 25, 99, 0.1);
  cursor: pointer;
}
.imageVideoDiv {
  width: 110px;
  height: 110px;
  margin-top: 6px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}
.deleteBTNDiv {
  height: 25px;
  width: 25px;
  background: white;
  position: relative;
  bottom: 100%;
  right: 2%;
  display: flex;
  padding-left: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  float: right;
  cursor: pointer;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 219%;
    color: #ffffff;
  }
  .v-btn >>> span {
    font-size: 14px;
    font-weight: 600;
  }
  .deleteBTNDiv {
    height: 25px;
    width: 25px;
    background: white;
    position: relative;
    bottom: 100%;
    right: 2%;
    display: flex;
    padding-left: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    float: right;
    cursor: pointer;
  }
}
</style>
